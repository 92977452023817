import { useEffect, useState } from "react";
import MintCard from "../components/MintCard";
import AssetCard from "../components/AssetCard";
import { fetchData } from "../lambda/lambda";

const url =
  "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/getOnePunks?indices=";

const countUrl =
  "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/getOnePunksCount";

function Minter() {
  const [count, setCount] = useState(0);
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    async function getLatest(_count) {
      const _assetMeta = await fetchData(
        url +
          [
            _count,
            _count - 1,
            _count - 2,
            _count - 3,
            count - 4,
            _count - 5,
            _count - 6,
          ]
            .map((id) => id.toString())
            .join(",")
      );
      setLatest(_assetMeta);
    }

    async function getCount() {
      const _assetMeta = await fetchData(countUrl);
      setCount(_assetMeta.data);
      getLatest(_assetMeta.data);
    }

    getCount();
  }, [count]);

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/Background.png"})`,
        minHeight: "100vh",
        backgroundSize: "contain",
      }}
      className="w-full pt-6 md:pt-0 flex flex-col items-center justify-center"
    >
      <MintCard count={count} />
      <div
        style={{ maxWidth: "880px", width: "95%" }}
        className="flex mt-3 pt-2 flex-col items-center rounded  bg-white flex-wrap"
      >
        <span className="text-black text-lg font-semibold mb-3">
          Recently Minted
        </span>
        <div className="flex pl-8 justify-center flex-wrap">
          {latest.length > 0 &&
            latest.map((u, index) => (
              <AssetCard
                modal={true}
                data={u}
                title={true}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Minter;
