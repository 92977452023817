import React, { useState } from "react";
import { useONE } from "../hooks/ONEHook";
import Button from "./Button";
import { ethers } from "ethers";

import { fetchData } from "../lambda/lambda";

const url =
  "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/syncMarketPunk?id=";

export default function ModalBuy({ data, handleShow, handleHide }) {
  const [verify, setVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmedPrice, setConfirmedPrice] = useState(0);
  const { marketService } = useONE();
  const genericBuyer = "0x0000000000000000000000000000000000000000";

  function renderMeta(meta, body) {
    const metaList = [];
    let i = 0;
    for (const attribute of meta) {
      if (
        (body && attribute.trait_type !== "Body") ||
        (!body && attribute.trait_type === "Body")
      )
        continue;
      metaList.push(
        <div
          className="w-full flex flex-col items-center justify-center"
          key={i}
        >
          {!body && (
            <span className="font-semibold">{attribute.trait_type}</span>
          )}
          <span className="text-sm">{attribute.value}</span>
        </div>
      );
      i++;
    }
    return metaList.reverse();
  }

  async function handleVerify() {
    setLoading(true);
    setVerify(true);
    const listing = await marketService?.instance?.getListing(data.id);
    if (listing[3].toString() !== data.price.toString()) {
      fetchData(url + data.id);
    }
    const price = ethers.utils.formatEther(listing[3]);
    setConfirmedPrice(price);
    setLoading(false);
  }

  async function handleBuy() {
    await marketService?.instance?.buy(data.id, {
      value: ethers.utils.parseEther(confirmedPrice),
    });
    handleHide();
  }

  return (
    <>
      {true ? (
        <>
          <div
            style={{
              top: "50%",
              left: "50%",
              width: 280,
              marginTop: -300,
              marginLeft: -130,
              position: "fixed",
            }}
            className="items-center flex flex-col overflow-x-hidden overflow-y-auto z-50 outline-none focus:outline-none"
          >
            {verify ? (
              <div className="h-full text-black px-4 py-4 border-0 rounded-lg justify-between shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
                {loading ? (
                  <span className="text-black w-full text-center mt-8 text-xl animate-pulse">
                    Loading...
                  </span>
                ) : (
                  <div className="flex  rounded flex-col">
                    <span className="border-2 rounded border-harmony-green bg-harmony-blue p-2 bg-opacity-50">
                      You are about to buy Harmony Punks #{data.id} for{" "}
                      <span className="font-bold">{confirmedPrice} ONE</span>
                    </span>
                    <Button
                      onClick={() => handleBuy()}
                      className="border mt-4 self-center w-20 text-sm rounded-full text-center border-black"
                      text="Confirm"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="h-full text-black px-4 py-4 border-0 items-center rounded-lg justify-between shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-col items-center text-black">
                  <span className="mb-3 text-lg font-bold">
                    Punk #{data.id}
                  </span>
                  <img alt="preview" className="w-32" src={data.image} />
                  <div className="flex flex-col text-black">
                    <span className="my-3 font-bold text-center">Type</span>
                    {renderMeta(data.attributes, true)}
                  </div>
                  <div className="flex flex-col text-black">
                    <span className="my-3 font-bold text-center">
                      Attributes
                    </span>
                    {renderMeta(data.attributes, false)}
                  </div>
                </div>
                {genericBuyer !== data.buyer && (
                  <div className="flex border-harmony-blue w-full border mt-3 rounded items-center flex-col">
                    <span>Private sell reserved for:</span>
                    <span>{data.buyer.substring(0, 15)}...</span>
                  </div>
                )}
                <Button
                  onClick={() => handleVerify()}
                  className="border mt-4 self-center w-20 text-sm rounded-full text-center border-black"
                  text="Buy"
                />
              </div>
            )}
          </div>

          <div
            onClick={() => handleHide(false)}
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div>
        </>
      ) : null}
    </>
  );
}
