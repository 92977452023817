import {
  Necklace,
  Hair,
  Earring,
  Beard,
  Body,
  Eyes,
  Mouth,
} from "./filters/FilterOptions";
import Select from "react-select";
import { useState } from "react";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "30px",
    height: "30px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),
};
function Filters({ onChange }) {
  const [activeFilters, setActiveFilters] = useState([]);

  function handleFilterChange(a, type) {
    let _filters;
    if (a) {
      _filters = [
        ...activeFilters.filter((f) => f.type !== type),
        {
          value: a.value,
          label: a.label,
          type: type,
        },
      ];
    } else {
      _filters = [...activeFilters.filter((f) => f.type !== type)];
    }
    setActiveFilters(_filters);
    onChange(_filters);
  }
  return (
    <>
      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Body")}
          maxHeight={100}
          placeholder="Body"
          options={Body}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Hair")}
          placeholder="Hair"
          options={Hair}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Eyes")}
          placeholder="Eyes"
          options={Eyes}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Beard")}
          placeholder="Beard"
          options={Beard}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Mouth")}
          placeholder="Mouth"
          options={Mouth}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Earring")}
          placeholder="Earring"
          options={Earring}
        />
      </div>
      <div className="w-40 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Necklace")}
          placeholder="Necklace"
          options={Necklace}
        />
      </div>
    </>
  );
}

export default Filters;
