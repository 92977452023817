import Button from "./Button";
import { useONE } from "../hooks/ONEHook";
import { useEffect, useState } from "react";
import question from "../assets/question.png";
import cs from "classnames";
import { fetchData } from "../lambda/lambda";

const url =
  "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/getOnePunk?index=";

function MintCard({ count }) {
  const { mintService } = useONE();
  const [loading, setLoading] = useState(false);
  const [mintedURL, setmintedURL] = useState(null);
  const [mintID, setMintID] = useState(null);
  const [mintedPunkId, setMintedPunkId] = useState(null);

  useEffect(() => {
    mintService.instance?.removeListener("Transfer");
    mintService.instance?.on("Transfer", async (a, b, c, d) => {
      const punkIndex = c.toNumber();
      if (loading && mintID === d.transactionHash) {
        const _data = await fetchData(url + punkIndex.toString());
        setLoading(false);
        setmintedURL(_data);
        setMintedPunkId(punkIndex);
      }
      //   getCount();
    });
    // getCount();
  }, [count, loading, mintID, mintService.instance]);

  async function handleMint() {
    const price = await mintService.instance?.price();
    const transaction = await mintService.instance?.mint({ value: price });
    setLoading(true);
    setMintID(transaction.hash);
    setmintedURL(null);
    setMintedPunkId(null);
  }

  function renderMeta() {
    const metaList = [];
    let i = 0;
    const meta = mintedURL.attributes;
    for (const attribute of meta) {
      metaList.push(
        <div className="" key={i}>
          <span className="text-sm font-semibold">{attribute.trait_type}</span>
          <li className="text-sm ml-0.5">{attribute.value}</li>
        </div>
      );
      i++;
    }
    return metaList;
  }

  return (
    <div
      className={cs(
        "border bg-white shadow-xl p-2 rounded pb-3 border-black h-96 flex flex-col  justify-between flex-wrap",
        { "w-72 items-center": !mintedURL, "w-96 h-100": mintedURL && !loading }
      )}
    >
      {mintedURL && !loading && (
        <div className="w-full flex flex-col">
          <span className="text-black font-semibold text-center">
            Congratulations you now own Punk #{mintedPunkId}
          </span>
          <div className="flex mt-2 pl-3 w-full h-72">
            <img
              alt="question"
              className={cs("transition-all ease-in-out duration-300", {
                "animate-bounce h-36 mt-9 w-36":
                  !loading && mintedURL && mintID,
              })}
              src={mintedURL.image}
            />
            <div className="text-black mt-2 ml-6 w-full flex flex-col">
              <span className="text-black mb-3 font-bold">Attributes</span>
              {renderMeta()}
            </div>
          </div>
        </div>
      )}

      {!mintedURL && (
        <img
          alt="question"
          className={cs("transition-all ease-in-out duration-300", {
            "animate-spin w-56": loading && mintID,
            "w-64": !loading && !mintedURL && !mintID,
          })}
          src={question}
        />
      )}

      <div className="w-full flex flex-col items-center">
        <Button
          onClick={handleMint}
          className="rounded-full border mb-2 px-2.5 w-36 h-8 text-center"
          text="Mint"
        />
        <span className="font-semibold mb-1 text-black">
          {count}/10,000 Punks Minted
        </span>
      </div>
    </div>
  );
}

export default MintCard;
