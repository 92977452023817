import React, { useEffect, useState } from "react";
import { useONE } from "../hooks/ONEHook";
import Button from "./Button";
import question from "../assets/question.png";
import _ from "lodash";

export default function RemoveModal({ handleShow, handleHide, _id = "" }) {
  const [punkID, setPunkID] = useState(_id);
  const [imageURL, setImageURL] = useState(null);
  const url =
    "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/onePunkURL?index=";
  const { mintService, marketService } = useONE();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (_id !== "") {
      handlePunkIDChange({ target: { value: _id } });
    }
  });

  async function handlePunkIDChange(e) {
    setError(null);
    const id = e.target.value;
    if (id < 0 || id === "") return;
    const _count = await mintService?.instance?.totalSupply();
    if (id > _count.toNumber()) return setImageURL(null);
    setImageURL(url + id);
    setPunkID(id);
  }

  async function handleRemove() {
    try {
      await marketService?.instance?.cancelListing(punkID);
    } catch (e) {
      if (
        e?.data?.message ===
        "execution reverted: HarmonyOnePunksMarket: caller is not the owner of the token"
      ) {
        setError("You are not the owner of this Punk");
      } else console.log(e);
    }
  }

  return (
    <>
      {true ? (
        <>
          <div
            style={{
              top: "50%",
              left: "50%",
              width: 280,
              marginTop: -300,
              marginLeft: -130,
              position: "fixed",
            }}
            className="items-center flex flex-col overflow-x-hidden overflow-y-auto z-50 outline-none focus:outline-none"
          >
            <div className="h-full text-black px-4 py-4 border-0 rounded-lg justify-between shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
              <img
                alt="preview"
                className="mb-4"
                src={!imageURL ? question : imageURL}
              />
              <span className="text-red-700 text-sm mb-3">{error}</span>
              <div className="flex flex-col mb-3">
                <span className="text-black">Punk ID</span>
                <input
                  placeholder={punkID}
                  type="number"
                  className="rounded pl-2 text-black w-full border-gray-100 border"
                  onChange={_.debounce(handlePunkIDChange, 900)}
                />
              </div>

              <div className="w-full flex justify-center">
                <Button
                  disabled={!punkID || punkID === ""}
                  onClick={() => handleRemove()}
                  className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                  text={"remove"}
                />
              </div>
            </div>
          </div>

          <div
            onClick={() => handleHide(false)}
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div>
        </>
      ) : null}
    </>
  );
}
