import React, { useState, createContext, useEffect } from "react";
import { useONE } from "../hooks/ONEHook";
import { fetchData } from "../lambda/lambda";

//create context to hold themes
export const PunksContext = createContext();

export const PunksContextProvider = ({ children }) => {
  const { mintService, address: wallet, isConnected } = useONE();
  const [ownedPunks, setAssets] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const url =
    "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/getOnePunks?indices=";

  useEffect(() => {
    async function fetchAssets() {
      setLoading(true);
      try {
        const ownerCount = await mintService.instance?.balanceOf(wallet);
        const ownerCountInt = ownerCount.toNumber();
        if (ownerCountInt > 0) {
          const _assetids = [];
          const range = [...Array(ownerCountInt).keys()];
          for (const i of range) {
            const assetId = await mintService.instance?.tokenOfOwnerByIndex(
              wallet,
              i
            );
            _assetids.push(assetId);
          }
          console.log(
            "list",
            _assetids.map((id) => id.toString())
          );

          const _assetMeta = await fetchData(
            url + _assetids.map((id) => id.toString()).join(",")
          );

          setAssets(_assetMeta);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    if (wallet && isConnected) {
      fetchAssets();
    }
  }, [isConnected, mintService.instance, wallet]);

  return (
    <PunksContext.Provider value={{ ownedPunks, isLoading }}>
      {children}
    </PunksContext.Provider>
  );
};
