import React, { useEffect, useState } from "react";
import { useONE } from "../hooks/ONEHook";
import Button from "./Button";
import { ethers } from "ethers";
import question from "../assets/question.png";
import _ from "lodash";

export default function ListModal({ handleShow, handleHide, _id = "" }) {
  const [punkID, setPunkID] = useState(_id);
  const [price, setPrice] = useState("");
  const [buyer, setBuyer] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const url =
    "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/onePunkURL?index=";
  const { mintService, marketService } = useONE();
  const [isApproved, setApproved] = useState(false);
  const [isListed, setIsListed] = useState(false);
  const [error, setError] = useState(null);
  const [waitingConfirm, setWaitingConfirm] = useState(false);

  useEffect(() => {
    if (_id !== "") {
      handlePunkIDChange({ target: { value: _id } });
    }
  });

  async function handlePunkIDChange(e) {
    setError(null);
    const id = e.target.value;
    if (id < 0 || id === "") return;
    const _count = await mintService?.instance?.totalSupply();
    if (id > _count.toNumber()) return setImageURL(null);
    setImageURL(url + id);
    setPunkID(id);
    setImageURL(url + id);
    const _approved = await mintService?.instance?.getApproved(id);
    setApproved(_approved === marketService?.instance?.address);
    const listInfo = await marketService?.instance?.getListing(id);
    setIsListed(listInfo[4]);
  }

  async function handleList() {
    if (!punkID || punkID === "" || !price || price === "") return;
    const ethPrice = ethers.utils.parseEther(price.toString());
    if (buyer && buyer !== "") {
      await marketService.instance?.createPrivateListing(
        punkID,
        ethPrice,
        buyer
      );
    } else {
      await marketService.instance?.createListing(punkID, ethPrice, {
        gasLimit: 124500,
      });
    }
  }

  async function handleVerifyApproval() {
    const _approved = await mintService?.instance?.getApproved(punkID);
    setApproved(_approved === marketService?.instance?.address);
    if (_approved === marketService?.instance?.address) {
      setWaitingConfirm(false);
    }
  }

  async function handleRemove() {
    await marketService?.instance?.cancelListing(punkID);
  }

  async function handleApprove() {
    const marketAddress = marketService?.instance?.address;
    try {
      await mintService?.instance?.approve(marketAddress, punkID);
      await mintService?.instance?.getApproved(punkID);
      setWaitingConfirm(true);
    } catch (e) {
      if (
        e?.data?.message ===
        "execution reverted: ERC721: approve caller is not owner nor approved for all"
      ) {
        setError("You are not the owner of this Punk");
      } else console.log(e);
    }
  }

  return (
    <>
      {true ? (
        <>
          <div
            style={{
              top: "50%",
              left: "50%",
              width: 280,
              marginTop: -300,
              marginLeft: -130,
              position: "fixed",
            }}
            className="items-center flex flex-col overflow-x-hidden overflow-y-auto z-50 outline-none focus:outline-none"
          >
            <div className="h-full text-black px-4 py-4 border-0 rounded-lg justify-between shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
              <img
                alt="preview"
                className="mb-4"
                src={!imageURL ? question : imageURL}
              />
              <span className="text-red-700 mb-3">{error}</span>
              <div className="flex flex-col mb-3">
                <span className="text-black">Punk ID</span>
                <input
                  placeholder={punkID}
                  type="number"
                  className="rounded pl-2 text-black w-full border-gray-100 border"
                  onChange={_.debounce(handlePunkIDChange, 900)}
                />
              </div>
              {isApproved && (
                <>
                  <div className="flex flex-col mb-3">
                    <span className="text-black">Price</span>
                    <input
                      type="number"
                      className="rounded pl-2 text-black w-full border-gray-100 border"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="ONE"
                    />
                  </div>
                  <div className="flex flex-col mb-3">
                    <span className="text-black">Buyer</span>
                    <input
                      className="rounded pl-2 text-black w-full border-gray-100 border"
                      value={buyer}
                      onChange={(e) => setBuyer(e.target.value)}
                      placeholder="(optional) - for private sales"
                    />
                  </div>
                </>
              )}

              <div className="w-full flex justify-center">
                {waitingConfirm ? (
                  <div className="flex w-full items-center flex-col">
                    <span className="w-full mb-2 text-center">
                      Wait for the transaction to be confirmed and click below
                    </span>
                    <Button
                      onClick={() => handleVerifyApproval()}
                      className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                      text={"Verify Approval"}
                    />
                  </div>
                ) : (
                  <>
                    <Button
                      disabled={
                        !punkID ||
                        punkID === "" ||
                        (isApproved && (!price || price === ""))
                      }
                      onClick={() =>
                        isApproved ? handleList() : handleApprove()
                      }
                      className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                      text={isApproved ? "List" : "Approve"}
                    />
                    {isListed && (
                      <Button
                        onClick={() => handleRemove()}
                        className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                        text={"Cancel Listing"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            onClick={() => handleHide(false)}
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div>
        </>
      ) : null}
    </>
  );
}
