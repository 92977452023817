// body
import f_alien from "../assets/Attributes/female_alien.png";
import f_ape from "../assets/Attributes/female_ape.png";
import f_bear from "../assets/Attributes/female_bear.png";
import f_black from "../assets/Attributes/female_black.png";
import f_brown from "../assets/Attributes/female_brown.png";
import f_white_body from "../assets/Attributes/female_white.png";
import f_zombie from "../assets/Attributes/female_zombie.png";
import m_alien from "../assets/Attributes/male_alien.png";
import m_ape from "../assets/Attributes/male_ape.png";
import m_bear from "../assets/Attributes/male_bear.png";
import m_bull from "../assets/Attributes/male_bull.png";
import m_zombie from "../assets/Attributes/male_zombie.png";
import m_white from "../assets/Attributes/male_white.png";
import m_skull from "../assets/Attributes/male_skull.png";
import f_skull from "../assets/Attributes/female_skull.png";
import m_black from "../assets/Attributes/male_black.png";
import m_brown from "../assets/Attributes/male_brown.png";

// head
import f_bandana from "../assets/Attributes/female_bandana.png";
import f_black_hair from "../assets/Attributes/female_black_hair.png";
import f_blue_hair from "../assets/Attributes/female_blue_hair.png";
import f_braided from "../assets/Attributes/female_braided_hair.png";
import f_bun from "../assets/Attributes/female_bun.png";
import f_crown from "../assets/Attributes/female_crown.png";
import f_hoodie from "../assets/Attributes/female_hoodie.png";
import f_kinnetd from "../assets/Attributes/female_knitted_hat.png";
import f_liea from "../assets/Attributes/female_leia_hair.png";
import f_magic from "../assets/Attributes/female_magic_hat.png";
import f_pilot from "../assets/Attributes/female_pilot_helmet.png";
import f_pink_hair from "../assets/Attributes/female_pink_hair.png";
import f_pink_hat from "../assets/Attributes/female_pink_hat.png";
import f_mohak from "../assets/Attributes/female_pink_mohawk.png";
import f_pony from "../assets/Attributes/female_ponytail.png";
import f_purple_hair from "../assets/Attributes/female_purple_hair.png";
import f_red_hair from "../assets/Attributes/female_red_hair.png";
import f_tiara from "../assets/Attributes/female_tiara.png";
import f_unicorn from "../assets/Attributes/female_unicorn_mohawk.png";
import f_white_hair from "../assets/Attributes/female_white_hair.png";
import m_arrow from "../assets/Attributes/male_arrow.png";
import m_beanie from "../assets/Attributes/male_beanie.png";
import m_cap from "../assets/Attributes/male_cap.png";
import m_white_hair from "../assets/Attributes/male_white_hair.png";
import m_white_head from "../assets/Attributes/male_white_head.png";
import m_mohawk from "../assets/Attributes/male_mohawk.png";
import m_vietnam from "../assets/Attributes/male_vietnamese_hat.png";
import m_fire_hat from "../assets/Attributes/male_fire_hat.png";
import m_headband from "../assets/Attributes/male_headband.png";
import m_japan_bun from "../assets/Attributes/male_japanese_bun.png";
import m_swiming_goggle from "../assets/Attributes/male_swimming_goggles.png";
import m_red_hair from "../assets/Attributes/male_red_hair.png";
import m_pink_hair from "../assets/Attributes/male_pink_hair.png";
import m_short_head from "../assets/Attributes/male_short_head.png";
import m_stringy_hair from "../assets/Attributes/male_stringy_hair.png";

// eyes
import f_big_sun from "../assets/Attributes/female_big_sunglasses.png";
import f_clown_eyes from "../assets/Attributes/female_clown_eyes.png";
import f_green_eyes from "../assets/Attributes/female_green_eyes.png";
import f_laser from "../assets/Attributes/female_laser_eyes.png";
import f_pink__eyes from "../assets/Attributes/female_pink_eyes.png";
import f_red_eyes from "../assets/Attributes/female_red_eyes.png";
import f_VR from "../assets/Attributes/female_VR.png";
import m_cool_glasses from "../assets/Attributes/male_cool_glasses.png";
import m_crazy_eyes from "../assets/Attributes/male_crazy_eyes.png";
import m_eye_mask from "../assets/Attributes/male_eye_mask.png";
import m_red_glasses from "../assets/Attributes/male_red_glasses.png";
import m_regular_shades from "../assets/Attributes/male_regular_shades.png";
import m_nerd from "../assets/Attributes/male_nerd_glasses.png";

// mouth
import f_black_lips from "../assets/Attributes/female_black_lips.png";
import f_mask from "../assets/Attributes/female_mask.png";
import f_pipe from "../assets/Attributes/female_pipe.png";
import f_red_lips from "../assets/Attributes/female_red_lips.png";
import f_rosy_cheeks from "../assets/Attributes/female_rosy_cheeks.png";
import f_vape from "../assets/Attributes/female_vape.png";
import m_blood from "../assets/Attributes/male_blood.png";
import m_mole from "../assets/Attributes/male_mole.png";
import m_mustage from "../assets/Attributes/male_mustage.png";
import m_mutton from "../assets/Attributes/male_muttonchops.png";
import m_smile from "../assets/Attributes/male_smile.png";
import m_cigat from "../assets/Attributes/male_cigarette.png";
import m_joint from "../assets/Attributes/male_joint.png";
import m_frown from "../assets/Attributes/male_frown.png";

// necklace
import f_choker from "../assets/Attributes/female_choker.png";
import m_gold_chin from "../assets/Attributes/male_gold_chin.png";

// earrings
import f_earring from "../assets/Attributes/female_earring.png";
import m_stud from "../assets/Attributes/male_stud.png";
import m_elf from "../assets/Attributes/male_elf_ears.png";

// beard
import m_chin_beard from "../assets/Attributes/male_chin_beard.png";
import m_grey_beard from "../assets/Attributes/male_grey_beard.png";
import m_red_beard from "../assets/Attributes/male_red_beard.png";
import m_yellow from "../assets/Attributes/male_yellow_beard.png";
import m_beard from "../assets/Attributes/male_beard.png";

function Attributes() {
  function renderBody() {
    return (
      <div className="flex px-2.5 flex-col">
        <span className="font-bold mb-4 text-xl">Punk Types</span>
        <div className="flex flex-wrap">
          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_bull} />
            </div>
            <span className="text-sm font-semibold">Bull</span>
            <span className="text-center">12</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_bear} />
              <img alt="example" className="w-24 h-24" src={f_bear} />
            </div>
            <span className="text-sm font-semibold">Bears</span>
            <span className="text-center">29</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_ape} />
              <img alt="example" className="w-24 h-24" src={f_ape} />
            </div>
            <span className="text-sm font-semibold">Apes</span>
            <span className="text-center">53</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_alien} />
              <img alt="example" className="w-24 h-24" src={f_alien} />
            </div>
            <span className="text-sm font-semibold">Aliens</span>
            <span className="text-center">54</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_skull} />
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_skull} />
            </div>
            <span className="text-sm font-semibold">Skull</span>
            <span className="text-center">138</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_zombie} />
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_zombie} />
            </div>
            <span className="text-sm font-semibold">Zombies</span>
            <span className="text-center">150</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_white_body}
              />
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_brown} />
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_black} />
            </div>
            <span className="text-sm font-semibold">Females</span>
            <span className="text-center">4,715</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_white} />
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_brown} />
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_black} />
            </div>
            <span className="text-sm font-semibold">Males</span>
            <span className="text-center">4,849 </span>
          </div>
        </div>
      </div>
    );
  }

  function renderGlasses() {
    return (
      <div className="flex px-2.5 flex-col">
        <span className="font-bold mb-4 text-xl">Attributes</span>
        <div className="flex flex-wrap">
          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_crown} />
            </div>
            <span className="text-sm font-semibold">Crown</span>
            <span className="text-center">50</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_beanie} />
            </div>
            <span className="text-sm font-semibold">Beanie</span>
            <span className="text-center">56</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_tiara} />
            </div>
            <span className="text-sm font-semibold">Tiara</span>
            <span className="text-center">57</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_unicorn} />
            </div>
            <span className="text-sm font-semibold">Unicorn Mohawk</span>
            <span className="text-center">67</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_arrow} />
            </div>
            <span className="text-sm font-semibold">Crossed Arrow</span>
            <span className="text-center">78</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_elf} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_elf_ears} /> */}
            </div>
            <span className="text-sm font-semibold">Elf Ears</span>
            <span className="text-center">80</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_magic} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_magic} />
            </div>
            <span className="text-sm font-semibold">Magic Hat</span>
            <span className="text-center">86</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_liea} />
            </div>
            <span className="text-sm font-semibold">Leia Hair</span>
            <span className="text-center">120</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_fire_hat}
              />
            </div>
            <span className="text-sm font-semibold">Fire Hat</span>
            <span className="text-center">123</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_pink_hat}
              />
            </div>
            <span className="text-sm font-semibold">Pink Hat</span>
            <span className="text-center">123</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_joint} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_joint} /> */}
            </div>
            <span className="text-sm font-semibold">Joint</span>
            <span className="text-center">148</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_vietnam} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_vietnam} /> */}
            </div>
            <span className="text-sm font-semibold">Vietnam Hat</span>
            <span className="text-center">189</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_blood} />
            </div>
            <span className="text-sm font-semibold">Blood</span>
            <span className="text-center">195</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_hoodie} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_hoodie} />
            </div>
            <span className="text-sm font-semibold">Hoodie</span>
            <span className="text-center">197</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_cap} />
            </div>
            <span className="text-sm font-semibold">Cap</span>
            <span className="text-center">222</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_mask} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_mask} />
            </div>
            <span className="text-sm font-semibold">Mask</span>
            <span className="text-center">230</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_purple_hair}
              />
            </div>
            <span className="text-sm font-semibold">Purple Hair</span>
            <span className="text-center">231</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_blue_hair}
              />
            </div>
            <span className="text-sm font-semibold">Blue Hair</span>
            <span className="text-center">232</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_pilot} />
            </div>
            <span className="text-sm font-semibold">Pilot Helmet</span>
            <span className="text-center">233</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_swiming_goggle}
              />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_swim_goggles} /> */}
            </div>
            <span className="text-sm font-semibold">Water Goggles</span>
            <span className="text-center">235</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_black_hair}
              />
            </div>
            <span className="text-sm font-semibold">Black Hair</span>
            <span className="text-center">237</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_pink_hair}
              />
            </div>
            <span className="text-sm font-semibold">Long Pink Hair</span>
            <span className="text-center">242</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_white_hair}
              />
            </div>
            <span className="text-sm font-semibold">White Hair</span>
            <span className="text-center">245</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_pony} />
            </div>
            <span className="text-sm font-semibold">Ponytail</span>
            <span className="text-center">248</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_braided} />
            </div>
            <span className="text-sm font-semibold">Braided Hair</span>
            <span className="text-center">249</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_mohak} />
            </div>
            <span className="text-sm font-semibold">Pink Mohawk</span>
            <span className="text-center">250</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_big_sun} />
            </div>
            <span className="text-sm font-semibold">Big Shades</span>
            <span className="text-center">251</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_bun} />
            </div>
            <span className="text-sm font-semibold">Bun</span>
            <span className="text-center">258</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_pipe} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_pipe} />
            </div>
            <span className="text-sm font-semibold">Pipe</span>
            <span className="text-center">259</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_red_hair}
              />
            </div>
            <span className="text-sm font-semibold">Red Hair</span>
            <span className="text-center">260</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_white_head}
              />
            </div>
            <span className="text-sm font-semibold">White Hair</span>
            <span className="text-center">264</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_red_hair}
              />
            </div>
            <span className="text-sm font-semibold">Crazy Hair</span>
            <span className="text-center">271</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_stringy_hair}
              />
            </div>
            <span className="text-sm font-semibold">Stringy Hair</span>
            <span className="text-center">278</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_short_head}
              />
            </div>
            <span className="text-sm font-semibold">Short Hair</span>
            <span className="text-center">282</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_white_hair}
              />
            </div>
            <span className="text-sm font-semibold">Grey Hair</span>
            <span className="text-center">285</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_japan_bun}
              />
            </div>
            <span className="text-sm font-semibold">Asian Bun</span>
            <span className="text-center">289</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_male_laser} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_laser} />
            </div>
            <span className="text-sm font-semibold">Laser Eyes</span>
            <span className="text-center">297</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_pink_hair}
              />
            </div>
            <span className="text-sm font-semibold">Pink Hair</span>
            <span className="text-center">300</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_mohawk} />
            </div>
            <span className="text-sm font-semibold">Mohawk</span>
            <span className="text-center">310</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_vr} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_VR} />
            </div>
            <span className="text-sm font-semibold">VR Glasses</span>
            <span className="text-center">312</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_red_beard}
              />
            </div>
            <span className="text-sm font-semibold">Red Beard</span>
            <span className="text-center">318</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_eye_mask}
              />
            </div>
            <span className="text-sm font-semibold">Bandit Mask</span>
            <span className="text-center">318</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_red_glasses}
              />
            </div>
            <span className="text-sm font-semibold">Red Glasses</span>
            <span className="text-center">322</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_smile} />
            </div>
            <span className="text-sm font-semibold">Smile</span>
            <span className="text-center">335</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_mutton} />
            </div>
            <span className="text-sm font-semibold">Muttonchops</span>
            <span className="text-center">342</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_rosy_cheeks}
              />
            </div>
            <span className="text-sm font-semibold">Pink Cheeks</span>
            <span className="text-center">351</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_headband}
              />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_head_band} /> */}
            </div>
            <span className="text-sm font-semibold">Headband</span>
            <span className="text-center">370</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_knitted} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_kinnetd} />
            </div>
            <span className="text-sm font-semibold">Knitted Hat</span>
            <span className="text-center">372</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_frown} />
            </div>
            <span className="text-sm font-semibold">Frown</span>
            <span className="text-center">372</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_choker} />
            </div>
            <span className="text-sm font-semibold">Choker</span>
            <span className="text-center">380</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_cool_glasses}
              />
            </div>
            <span className="text-sm font-semibold">Cool Shades</span>
            <span className="text-center">394</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_beard} />
            </div>
            <span className="text-sm font-semibold">Beard</span>
            <span className="text-center">406</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_mustage} />
            </div>
            <span className="text-sm font-semibold">Mustache</span>
            <span className="text-center">412</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_yellow} />
            </div>
            <span className="text-sm font-semibold">Yellow Beard</span>
            <span className="text-center">420</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_chin_beard}
              />
            </div>
            <span className="text-sm font-semibold">Chin Beard</span>
            <span className="text-center">421</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_grey_beard}
              />
            </div>
            <span className="text-sm font-semibold">Grey Beard</span>
            <span className="text-center">427</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_red_eyes}
              />
            </div>
            <span className="text-sm font-semibold">Red Eyes</span>
            <span className="text-center">432</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_vape} /> */}
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_vape} />
            </div>
            <span className="text-sm font-semibold">Vape</span>
            <span className="text-center">451</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_green_eyes}
              />
            </div>
            <span className="text-sm font-semibold">Green Eyes</span>
            <span className="text-center">458</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_black_lips}
              />
            </div>
            <span className="text-sm font-semibold">Black Lips</span>
            <span className="text-center">461</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_pink__eyes}
              />
            </div>
            <span className="text-sm font-semibold">Pink Eyes</span>
            <span className="text-center">469</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_red_lips}
              />
            </div>
            <span className="text-sm font-semibold">Red Lips</span>
            <span className="text-center">492</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 h-24" src={f_bandana} />
            </div>
            <span className="text-sm font-semibold">Bandana</span>
            <span className="text-center">529</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_gold_chin}
              />
            </div>
            <span className="text-sm font-semibold">Gold Chain</span>
            <span className="text-center">574</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_crazy_eyes}
              />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_crazy_eyes} /> */}
            </div>
            <span className="text-sm font-semibold">Crazy Eyes</span>
            <span className="text-center">602</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_clown_eyes} /> */}
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={f_clown_eyes}
              />
            </div>
            <span className="text-sm font-semibold">Clown Eyes</span>
            <span className="text-center">628</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_regular_shades} /> */}
              <img
                alt="example"
                className="w-24 mr-1.5 h-24"
                src={m_regular_shades}
              />
            </div>
            <span className="text-sm font-semibold">Shades</span>
            <span className="text-center">688</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_cigat} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={female_cigarette} /> */}
            </div>
            <span className="text-sm font-semibold">Cigarette</span>
            <span className="text-center">859</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_mole} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_mole} /> */}
            </div>
            <span className="text-sm font-semibold">Mole</span>
            <span className="text-center">874</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_nerd} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_nerd} /> */}
            </div>
            <span className="text-sm font-semibold">Nerd Glasses</span>
            <span className="text-center">896</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={f_earring} />
            </div>
            <span className="text-sm font-semibold">Earrings</span>
            <span className="text-center">1,236</span>
          </div>

          <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
            <div className="flex items-center">
              <img alt="example" className="w-24 mr-1.5 h-24" src={m_stud} />
              {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_stud} /> */}
            </div>
            <span className="text-sm font-semibold">Studs</span>
            <span className="text-center">2074</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="text-black w-full mb-20 mt-8 flex flex-col items-center">
      <div className="flex max-w-3xl w-full flex-col">
        {/* Bodies */}
        {renderBody()}
        {/* Head Accessories */}
        {renderGlasses()}
      </div>
    </div>
  );
}

export default Attributes;
