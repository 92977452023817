import cs from "classnames";
import Modal from "./ModalBuy";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function SellAssetCard({ data, title, showSend = true, punks }) {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const genericBuyer = "0x0000000000000000000000000000000000000000";
  return (
    <div
      style={{ maxWidth: 150 }}
      className={cs(
        "flex pb-0 pt-1 pl-1 pr-1 bg-white border-gray-900 rounded border flex-col mr-6 mb-2 pb-2"
      )}
    >
      {title && (
        <div className="flex mb-1 items-center justify-between w-full">
          <span className="text-gray-900 font-bold">{`# ${data.id}`}</span>
          {genericBuyer !== data.buyer && (
            <span
              style={{ marginLeft: "3px" }}
              className="text-black border border-black  px-1.5 text-xs bg-gradient-to-r from-harmony-blue to-harmony-green rounded-full "
            >
              Private
            </span>
          )}
          {punks && showModal && (
            <Modal
              handleHide={() => setShowModal(false)}
              handleShow={() => setShowModal(true)}
              data={data}
              showSend={showSend}
            />
          )}
        </div>
      )}
      <img
        className=" cursor-pointer"
        onClick={() => history.push("/harmonypunk/" + data.id)}
        alt="asset"
        src={data.image}
      />
      {!punks && showModal && (
        <Modal
          handleHide={() => setShowModal(false)}
          handleShow={() => setShowModal(true)}
          data={data}
          showSend={showSend}
        />
      )}
      <div className="text-sm w-full bg-gradient-to-r from-harmony-green to-harmony-blue flex mt-1 justify-center">
        <span className="text-black">
          {nFormatter(parseInt(data.price).toFixed(1))}
          <span className="font-semibold ml-0.5">ONE</span>
        </span>
      </div>
      <div
        onClick={() => setShowModal(true)}
        className="text-sm w-full bg-gradient-to-r from-harmony-green to-harmony-blue flex mt-1 justify-center"
      >
        <span className="text-black">
          <span className="font-semibold cursor-pointer ml-0.5">Buy</span>
        </span>
      </div>
    </div>
  );
}

export default SellAssetCard;
