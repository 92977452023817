export const Mouth = [
  {
    value: "Cigarette",
    label: "Cigarette",
  },
  {
    value: "Pipe",
    label: "Pipe",
  },
  {
    value: "Black Lips",
    label: "Black Lips",
  },
  {
    value: "Pink Cheeks",
    label: "Pink Cheeks",
  },
  {
    value: "Vape",
    label: "Vape",
  },
  {
    value: "Joint",
    label: "Joint",
  },
  {
    value: "Mole",
    label: "Mole",
  },
  {
    value: "Frown",
    label: "Frown",
  },
  {
    value: "Blood",
    label: "Blood",
  },
  {
    value: "Mask",
    label: "Mask",
  },
  {
    value: "Red Lips",
    label: "Red Lips",
  },
  {
    value: "Smile",
    label: "Smile",
  },
];
export const Eyes = [
  {
    value: "Cool Shades",
    label: "Cool Shades",
  },
  {
    value: "Shades",
    label: "Shades",
  },
  {
    value: "Big Shades",
    label: "Big Shades",
  },
  {
    value: "VR Glasses",
    label: "VR Glasses",
  },
  {
    value: "Green Eyes",
    label: "Green Eyes",
  },
  {
    value: "Nerd Glasses",
    label: "Nerd Glasses",
  },
  {
    value: "Bandit Mask",
    label: "Bandit Mask",
  },
  {
    value: "Crazy Eyes",
    label: "Crazy Eyes",
  },
  {
    value: "Red Eyes",
    label: "Red Eyes",
  },
  {
    value: "Pink Eyes",
    label: "Pink Eyes",
  },
  {
    value: "Laser Eyes",
    label: "Laser Eyes",
  },
  {
    value: "Clown Eyes",
    label: "Clown Eyes",
  },
  {
    value: "Red Glasses",
    label: "Red Glasses",
  },
];
export const Body = [
  {
    value: "White Male",
    label: "White Male",
  },
  {
    value: "Brown Female",
    label: "Brown Female",
  },
  {
    value: "White Female",
    label: "White Female",
  },
  {
    value: "Brown Male",
    label: "Brown Male",
  },
  {
    value: "Black Male",
    label: "Black Male",
  },
  {
    value: "Zombie",
    label: "Zombie",
  },
  {
    value: "Black Female",
    label: "Black Female",
  },
  {
    value: "Bear",
    label: "Bear",
  },
  {
    value: "Skull",
    label: "Skull",
  },
  {
    value: "Alien",
    label: "Alien",
  },
  {
    value: "Ape",
    label: "Ape",
  },
  {
    value: "Bull",
    label: "Bull",
  },
];

export const Beard = [
  {
    value: "Yellow Beard",
    label: "Yellow Beard",
  },
  {
    value: "Chin Beard",
    label: "Chin Beard",
  },
  {
    value: "Muttonchops",
    label: "Muttonchops",
  },
  {
    value: "Beard",
    label: "Beard",
  },
  {
    value: "Red Beard",
    label: "Red Beard",
  },
  {
    value: "Grey Beard",
    label: "Grey Beard",
  },
  {
    value: "Mustache",
    label: "Mustache",
  },
];
export const Necklace = [
  {
    value: "Gold Chain",
    label: "Gold Chain",
  },
  {
    value: "Choker",
    label: "Choker",
  },
];
export const Hair = [
  {
    value: "Red Hair",
    label: "Red Hair",
  },
  {
    value: "Blue Hair",
    label: "Blue Hair",
  },
  {
    value: "Pink Hair",
    label: "Pink Hair",
  },
  {
    value: "Crossed Arrow",
    label: "Crossed Arrow",
  },
  {
    value: "Unicorn Mohawk",
    label: "Unicorn Mohawk",
  },
  {
    value: "Stringy Hair",
    label: "Stringy Hair",
  },
  {
    value: "White Hair",
    label: "White Hair",
  },
  {
    value: "Pink Hat",
    label: "Pink Hat",
  },
  {
    value: "Hoodie",
    label: "Hoodie",
  },
  {
    value: "Purple Hair",
    label: "Purple Hair",
  },
  {
    value: "Long Pink Hair",
    label: "Long Pink Hair",
  },
  {
    value: "Grey Hair",
    label: "Grey Hair",
  },
  {
    value: "Ponytail",
    label: "Ponytail",
  },
  {
    value: "Headband",
    label: "Headband",
  },
  {
    value: "Bandana",
    label: "Bandana",
  },
  {
    value: "Short Hair",
    label: "Short Hair",
  },
  {
    value: "Black Hair",
    label: "Black Hair",
  },
  {
    value: "Water Goggles",
    label: "Water Goggles",
  },
  {
    value: "Laia Hair",
    label: "Laia Hair",
  },
  {
    value: "Knitted Hat",
    label: "Knitted Hat",
  },
  {
    value: "Braided Hair",
    label: "Braided Hair",
  },
  {
    value: "Pink Mohwak",
    label: "Pink Mohwak",
  },
  {
    value: "Mohawk",
    label: "Mohawk",
  },
  {
    value: "Pilot Helmet",
    label: "Pilot Helmet",
  },
  {
    value: "Fire Hat",
    label: "Fire Hat",
  },
  {
    value: "Cap",
    label: "Cap",
  },
  {
    value: "Beanie",
    label: "Beanie",
  },
  {
    value: "Vietnam Hat",
    label: "Vietnam Hat",
  },
  {
    value: "Bun",
    label: "Bun",
  },
  {
    value: "Magic Hat",
    label: "Magic Hat",
  },
  {
    value: "Asian Bun",
    label: "Asian Bun",
  },
  {
    value: "Crown",
    label: "Crown",
  },
  {
    value: "Tiara",
    label: "Tiara",
  },
];
export const Earring = [
  {
    value: "Stud",
    label: "Stud",
  },
  {
    value: "Earring",
    label: "Earring",
  },
  {
    value: "Elf Ears",
    label: "Elf Ears",
  },
];
