import cs from "classnames";
import Modal from "./Modal";
import { useHistory } from "react-router-dom";

function AssetCard({ data, title, showSend = true, punks, onList }) {
  const history = useHistory();

  return (
    <div
      style={{ maxWidth: 110 }}
      className={cs(
        "flex border bg-white rounded px-2 flex-col mr-6 mb-2 pb-2"
      )}
    >
      {title && (
        <div className="flex items-center justify-between w-full">
          <span className="text-gray-900 font-bold">{`# ${data.id}`}</span>
          {punks && <Modal data={data} showSend={showSend} />}
        </div>
      )}
      <img
        className="mb-2.5 cursor-pointer"
        onClick={() => history.push("/harmonypunk/" + data.id)}
        alt="asset"
        src={data.image}
      />
      {!punks && <Modal data={data} showSend={showSend} />}
      {onList !== undefined && (
        <div className="w-full flex justify-center">
          <span
            onClick={() => onList(data.id)}
            className="cursor-pointer underline"
          >
            List
          </span>
        </div>
      )}
    </div>
  );
}

export default AssetCard;
