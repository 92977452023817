import { useState, useEffect } from "react";
import { fetchData } from "../lambda/lambda";
import { useONE } from "../hooks/ONEHook";
import ListModal from "../components/ListModal";
import { useParams } from "react-router-dom";
import question from "../assets/question.png";
import Modal from "../components/Modal";
import tag from "../assets/price-tag.svg";
const historyUrl =
  "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/getPunkHistory?id=";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function Detail() {
  const { mintService } = useONE();
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [history, setHistory] = useState([]);

  function renderMeta(meta, body) {
    const metaList = [];
    let i = 0;
    for (const attribute of meta) {
      console.log(attribute.trait_type, body);
      if (
        (body && attribute.trait_type !== "Body") ||
        (!body && attribute.trait_type === "Body")
      )
        continue;
      metaList.push(
        <div className="flex flex-col items-center mb-2" key={i}>
          {!body && <span className="font-medium">{attribute.trait_type}</span>}
          <span className="text-sm">{attribute.value}</span>
        </div>
      );
      i++;
    }
    return metaList;
  }

  function renderHistory() {
    return history
      .sort((a, b) => {
        return b.time - a.time;
      })
      .map((h) => {
        if (h?.event === "ListingCreated") {
          return (
            <div className="flex p-2 flex-col border mb-4 w-full">
              <div className="flex items-center">
                <div className="px-1.5 rounded text-black flex justify-center items-center h-8 bg-gradient-to-r from-harmony-blue to-harmony-green">
                  <img  alt="punk detail" className="w-5" src={tag} />
                </div>
                <div className="flex justify-between w-full">
                  <span className="ml-2 font-medium text-black">
                    Listing Created
                  </span>
                  <span className="ml-2 font-medium text-black">
                    {formatDate(h.time)}
                  </span>
                </div>
              </div>
              <div className=" mt-1.5 items-center flex">
                <span className=" font-bold text-black">
                  seller:{" "}
                  <span className="font-normal">
                    {" "}
                    {h.seller?.substring(0, 7)}
                  </span>
                </span>
                <span className="ml-3 font-bold text-black">
                  price: <span className="font-normal"> {h.price} ONE</span>
                </span>
              </div>
            </div>
          );
        } else if (h.event === "Sale") {
          return (
            <div className="flex p-2 flex-col border mb-4 w-full">
              <div className="flex items-center">
                <div className="px-1.5 w-8 rounded text-black flex justify-center items-center h-8 bg-gradient-to-r from-harmony-blue to-harmony-green">
                  $
                </div>
                <div className="flex justify-between w-full">
                  <span className="ml-2 font-medium text-black">Item Sold</span>
                  <span className="ml-2 font-medium text-black">
                    {formatDate(h.time)}
                  </span>
                </div>
              </div>

              <div className=" mt-1.5 items-center flex">
                <span className=" font-bold text-black">
                  seller:{" "}
                  <span className="font-normal">
                    {" "}
                    {h.seller?.substring(0, 7)}
                  </span>
                </span>
                <span className="ml-3 font-bold text-black">
                  price: <span className="font-normal"> {h.price} ONE</span>
                </span>
                <span className="ml-3 font-bold text-black">
                  buyer:{" "}
                  <span className="font-normal">
                    {" "}
                    {h.buyer?.substring(0, 7)}
                  </span>
                </span>
              </div>
            </div>
          );
        } else if (h.event === "Transfer") {
          return (
            <div className="flex p-2 flex-col border mb-4 w-full">
              <div className="flex items-center">
                <div className="px-1.5 w-8 rounded text-black flex justify-center items-center h-8 bg-gradient-to-r from-harmony-blue to-harmony-green">
                  $
                </div>
                <div className="flex justify-between w-full">
                  <span className="ml-2 font-medium text-black">Transfer</span>
                  <span className="ml-2 font-medium text-black">
                    {formatDate(h.time)}
                  </span>
                </div>
              </div>

              <div className=" mt-1.5 items-center flex">
                <span className=" font-bold text-black">
                  from:{" "}
                  <span className="font-normal">
                    {" "}
                    {h.transferFrom?.substring(0, 7)}
                  </span>
                </span>
                <span className="ml-3 font-bold text-black">
                  to:{" "}
                  <span className="font-normal">
                    {" "}
                    {h.transferTo?.substring(0, 7)}
                  </span>
                </span>
              </div>
            </div>
          );
        } else return null;
      });
  }

  const url =
    "https://1qj46izuch.execute-api.us-east-1.amazonaws.com/getOnePunks?indices=";

  useEffect(() => {
    fetchData(url + id).then((data) => {
      if (data) setData(data[0]);
      console.log("dataaa");
    });
    fetchData(historyUrl + id).then((d) => {
      setHistory(d);
    });
  }, [id, mintService?.instance]);

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/Background.png"})`,
        minHeight: "100vh",
        backgroundSize: "contain",
      }}
      className="flex justify-center"
    >
      <div className="flex bg-white flex-col flex-wrap max-w-2xl w-full items-center">
        <div className="text-black items-center flex flex-col px-6 py-8 border-black">
          <span className="text-black mb-4 text-center font-bold text-2xl">
            Harmony Punk #{data?.id}
          </span>
          <div className="flex">
            <Modal data={data} showSend={true} />
            {showModal && (
              <ListModal
                _id={data?.id}
                handleHide={() => setShowModal(false)}
              />
            )}
            <div className="w-full ml-4 flex justify-center">
              <span
                onClick={() => setShowModal(true)}
                className="cursor-pointer underline"
              >
                List
              </span>
            </div>
          </div>
          <img
            alt="hpunk"
            className="w-44 h-44 cursor-pointer"
            onClick={() => window.open(`https://punk-one-assets.s3.amazonaws.com/one_punk_${id}.png`)}
            src={data?.image || question}
          />
          <span className="text-center font-bold text-lg mt-8">Type</span>
          {renderMeta(
            data?.attributes || [
              { trait_type: "Eyes", value: "Crazy Eyes" },
              { trait_type: "Body", value: "Brown Male" },
              { trait_type: "Beard", value: "Muttonchops" },
            ],
            true
          )}
          <span className="text-center font-bold text-lg mt-4">Attributes</span>
          {renderMeta(
            data?.attributes || [
              { trait_type: "Eyes", value: "Crazy Eyes" },
              { trait_type: "Body", value: "Brown Male" },
              { trait_type: "Beard", value: "Muttonchops" },
            ]
          )}
        </div>
        <div className="flex max-w-lg w-full mt-0 flex-col">
          <span className="text-black mb-4 text-center font-bold text-lg">
            Market History
          </span>
          {renderHistory()}
        </div>
      </div>
    </div>
  );
}

export default Detail;
