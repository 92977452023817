import { useHistory } from "react-router-dom";
import cs from "classnames";
import { useONE } from "../hooks/ONEHook";
import logo from "../assets/logo.png";
import Button from "../components/Button";
import Twitter from "../assets/twitter";
import Telegram from "../assets/telegram";
import Discord from "../assets/discord";
import logocats from "../assets/logocats.png"
import Modal from "../components/Modal";
import { useState, useEffect, useRef } from "react";

function SideBar() {
  const history = useHistory();
  const { addNetwork, isMetamask, isConnected, address } = useONE();
  const [show, setShow] = useState(false);
  const node = useRef();

  function handleClick(e) {
    if (node.current?.contains(e.target)) {
      // inside click
      return;
    }
    setShow(false);
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  function getConnectButton() {
    if (!isMetamask) {
      return "Setup Metamask";
    } else if (!isConnected) {
      return "Connect Wallet";
    }
    return address.substr(0, 10);
  }

  return (
    <>
      <button
        style={{ top: 10, left: 10 }}
        onClick={() => setShow(true)}
        className="md:hidden flex bg-white rounded border-2 border-black p-2 flex-col z-50 fixed"
      >
        <span className="bg-black rounded" style={{ width: 22, height: 2 }} />
        <span
          className="bg-black rounded"
          style={{ width: 22, height: 2, marginBottom: 6, marginTop: 6 }}
        />
        <span className="bg-black rounded" style={{ width: 22, height: 2 }} />
      </button>
      {show && (
        <div
          ref={node}
          className={cs(
            "fixed ease-linear transition-all duration-700 top-0 h-full z-50 left-0 border bg-white bottom-0 flex w-52 flex-col"
          )}
        >
          <Button
            className="text-xl mx-4 mt-4 text-center mb-7 w-8 self-end"
            text="X"
            onClick={() => setShow(false)}
          />
          <div className="self-center w-44 flex h-full justify-between items-center flex-col">
            <div className="items-center flex flex-col justify-center w-full">
              <img alt="logo" className="w-28 mt-0 mb-20 h-28" src={logo} />
              <Button
                onClick={() =>
                  isMetamask
                    ? addNetwork()
                    : window.open(
                        "https://docs.harmony.one/home/network/wallets/browser-extensions-wallets/metamask-wallet",
                        "_blank"
                      )
                }
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text={getConnectButton()}
              />
              <Button
                onClick={() => history.push("/")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="Mint"
              />
              <Button
                onClick={() => history.push("/view")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="View My Punks"
              />
              <Button
                onClick={() => history.push("/punks")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="View Minted Punks"
              />
              <Button
                onClick={() => history.push("/attributes")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="Attributes"
              />
              <Button
                onClick={() => history.push("/marketplace")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="Marketplace"
              />
                                  <Button
                onClick={() => window.open('https://kitties.harmonypunks.one/')}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text={<div className="flex justify-center gap-2 items-center">
                    <img className="w-8 h-8" alt="preview" src={logocats} />
                    <span>Visit Kitties</span>
                </div>}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <a
                  target="_blank"
                  href="https://twitter.com/HarmonyOnePunks"
                  className="text-black mb-4 rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/ajM977tEev"
                  className="text-black mb-4 rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
                  rel="noreferrer"
                >
                  <Discord />
                </a>
                <a
                  target="_blank"
                  href="https://t.me/joinchat/93-AJZ4P5_sxMTBh"
                  className="text-black mb-4 rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
                  rel="noreferrer"
                >
                  <Telegram />
                </a>
              </div>
              <Modal
                button="About"
                body="Harmony One Punks is an NFT following the HRC721 standard. Harmony One Punks were algorithimcally generated from a set of attributes to create 10,000 unique digital collectibles only available on Harmony One! This project is not affiliated with Larva Labs but was inspired by the original Crypto Punks. Harmony One Punks can be thought of as a fork of the original punks created to bring a similar but unique experience to users of the Harmony One chain."
              />
            </div>
          </div>
        </div>
      )}
      <div
        style={{ maxHeight: "100vh", height: "100vh" }}
        className={cs(
          "md:w-sidebar shadow-xl items-center justify-between flex-grow hidden md:flex flex-col text-white z-40 pt-6  fixed top-0 left-0 bottom-0 bg-white"
        )}
      >
        <div className="items-center h-full flex flex-col justify-between w-full">
          <div className="flex items-center flex-col">
            <img alt="logo" className="w-28 mt-8 h-28" src={logo} />
            <div className="items-center mt-10 flex flex-col justify-center w-full">
              <Button
                onClick={() =>
                  isMetamask
                    ? addNetwork()
                    : window.open(
                        "https://docs.harmony.one/home/network/wallets/browser-extensions-wallets/metamask-wallet",
                        "_blank"
                      )
                }
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text={getConnectButton()}
              />
              <Button
                onClick={() => history.push("/")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="Mint"
              />
              <Button
                onClick={() => history.push("/view")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="View My Punks"
              />
              <Button
                onClick={() => history.push("/punks")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="View Minted Punks"
              />
              <Button
                onClick={() => history.push("/attributes")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="Attributes"
              />
              <Button
                onClick={() => history.push("/marketplace")}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text="Marketplace"
              />
                                            <Button
                onClick={() => window.open('https://kitties.harmonypunks.one/')}
                className="rounded-full border mb-3 px-2.5 w-44 h-8 text-center"
                text={<div className="flex justify-center gap-2 items-center">
                    <img className="w-8 h-8" alt="preview" src={logocats} />
                    <span>Visit Kitties</span>
                </div>}
              />

            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <a
                target="_blank"
                href="https://twitter.com/HarmonyOnePunks"
                className="text-black mb-4 rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                target="_blank"
                href="https://discord.gg/ajM977tEev"
                className="text-black mb-4 rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
                rel="noreferrer"
              >
                <Discord />
              </a>
              <a
                target="_blank"
                href="https://t.me/joinchat/93-AJZ4P5_sxMTBh"
                className="text-black mb-4 rounded-full text-blue-600 bottom-14 p-2.5 right-10 w-12"
                rel="noreferrer"
              >
                <Telegram />
              </a>
            </div>
            <Modal
              button="About"
              body="Harmony One Punks is an NFT following the HRC721 standard. Harmony One Punks were algorithimcally generated from a set of attributes to create 10,000 unique digital collectibles only available on Harmony One! This project is not affiliated with Larva Labs but was inspired by the original Crypto Punks. Harmony One Punks can be thought of as a fork of the original punks created to bring a similar but unique experience to users of the Harmony One chain."
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
