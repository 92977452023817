import { useState, useEffect } from "react";
import cs from "classnames";
import { fetchData } from "../lambda/lambda";
import SellAssetCard from "../components/SellAssetCard";
// import { PunksContext } from "../contexts/punksContext";
import ListModal from "../components/ListModal";
import RemoveModal from "../components/RemoveModal";
import Modal from "../components/MarketInfoModal";
import Select from "react-select";
import qs from "qs";
import {
  Necklace,
  Hair,
  Earring,
  Beard,
  Body,
  Eyes,
  Mouth,
} from "./filters/FilterOptions";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "30px",
    height: "30px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),
};

function Exchnage() {
  const [list, setList] = useState();
  const [punks, setPunks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [lastKey, setLastKey] = useState("");
  const [filters, setFilters] = useState([]);
  //   const { ownedPunks } = useContext(PunksContext);

  //   console.log(ownedPunks)

  function renderList() {
    return (
      <>
        <ListModal handleHide={() => setList(false)} />
      </>
    );
  }

  function prepareFilters(stringFilters) {
    const curatedFilters = {};
    stringFilters.map((f) => {
      curatedFilters[f.type] = f.value;
      return true;
    });
    const _f = qs.stringify(curatedFilters);
    return _f;
  }

  async function fetchListingsFiltered(stringFilters, lastkey) {
    const _f = prepareFilters(stringFilters);
    setLoading(true);
    const endpoint = "getListedOnePunksSearch";
    const url = lastkey
      ? `https://1qj46izuch.execute-api.us-east-1.amazonaws.com/${endpoint}?limit=200&${_f}&id=${lastkey?.id}&price=${lastkey?.price}`
      : `https://1qj46izuch.execute-api.us-east-1.amazonaws.com/${endpoint}?limit=200&${_f}`;
    const data = await fetchData(url);
    setPunks(data.items);
    setLastKey(data.lastKey);
    if (data.items.length === 0 && data.lastKey) {
      return fetchListingsFiltered(stringFilters, data.lastKey);
    }
    setLoading(false);
  }

  async function fetchListings(lastkey) {
    const endpoint =
      filters.length > 0 ? "getListedOnePunksSearch" : "getListedOnePunks";
    if (!lastkey) return;
    const query = filters.length > 0 ? `&${prepareFilters(filters)}` : "";
    const data = await fetchData(
      `https://1qj46izuch.execute-api.us-east-1.amazonaws.com/${endpoint}?limit=200&id=${lastkey?.id}&price=${lastkey?.price}` +
        query
    );
    setPunks([...punks, ...data.items]);
    setLastKey(data.lastKey);
    if (data.items.length === 0 && data.lastKey) {
      return fetchListings(data.lastKey);
    }
    setLoading(false);
  }

  function renderBuy() {
    return (
      <div className="w-full bg-gradient-to-r from-harmony-blue to-harmony-green pt-5 flex flex-col">
        {punks?.length > 0 && (
          <>
            <div className="bg-gradient-to-r  from-harmony-blue to-harmony-green flex justify-center flex-wrap">
              {punks?.map((p, i) => (
                <SellAssetCard
                  punks={true}
                  showSend={false}
                  title={true}
                  modal={false}
                  data={p}
                  index={i}
                  key={i}
                />
              ))}
            </div>
            {lastKey && (
              <span
                onClick={() => fetchListings(lastKey)}
                className={cs(
                  "bg-white px-6 w-44 text-center text-xl  self-center my-5 ml-2 cursor-pointer rounded-full border px-2 text-gray-900 font-semibold",
                  { "bg-yellow-100": list }
                )}
              >
                Load More
              </span>
            )}
          </>
        )}
        {!loading && punks.length === 0 && (
          <span className="w-full text-center pt-20 font-bold text-lg">
            No Harmony Punks for Sale
          </span>
        )}
      </div>
    );
  }

  useEffect(() => {
    setLoading(true);
    const endpoint = "getListedOnePunks";
    fetchData(
      `https://1qj46izuch.execute-api.us-east-1.amazonaws.com/${endpoint}?limit=200`
    ).then((data) => {
      setPunks(data.items);
      setLastKey(data.lastKey);
      setLoading(false);
    });
  }, []);

  function handleFilterChange(a, b, c, d) {
    const _f = filters.filter((f) => f.type !== b);
    let obj;
    if (a) {
      obj = { type: b, value: a.value };
      _f.push(obj);
    }

    setFilters(_f);
    fetchListingsFiltered(_f);
  }

  return (
    <div className="w-full bg-gradient-to-r from-harmony-blue to-harmony-green h-full flex flex-col">
      {showRemoveModal && (
        <RemoveModal handleHide={() => setShowRemoveModal(false)} />
      )}
      {helpModal && (
        <Modal
          onClose={() => setHelpModal(false)}
          showSend={false}
          marginTop={-300}
          width={350}
          button="Help"
          body={
            <div className="flex flex-col">
              <span className="text-xl text-center font-bold">
                Welcome to the Harmony Punks Marketplace!
              </span>
              <span className="my-3 font-semibold">
                To list a punk for sale follow these steps:{" "}
              </span>
              <span className="text-sm">
                1. Click List Punk to open the listing creator
              </span>
              <span className="text-sm">
                2. Select the Punk ID you want to list for sale
              </span>
              <span className="text-sm">
                3. Click the Approve button to allow the marketplace to list
                your punk
              </span>
              <span className="text-sm">
                4. Once the transaction has processed click Verify Approval
              </span>
              <span className="text-sm">
                5. Enter the listing price of the punk in ONE
              </span>
              <span className="text-sm">
                6. Enter a buyer address only if punk is for a private sale only
              </span>
              <span className="text-sm">
                7. Click List to list the punk on the market place
              </span>
              <span className="italic mt-2 text-sm">
                The Marketplace takes a 5% royalty on all sales
              </span>
              <span className="my-3 font-semibold">
                To buy a punk follow these steps:{" "}
              </span>
              <span className="text-sm">
                1. Click on the punk you would like to purchase
              </span>
              <span className="text-sm">2. Click the Buy button</span>
              <span className="text-sm">
                3. Approve the transaction in MetaMask
              </span>
              <span className="my-3 font-semibold">
                To cancel a listing follow these steps:{" "}
              </span>
              <span className="text-sm">
                1. Click the Cancel Listing button{" "}
              </span>
              <span className="text-sm">
                2. Select the ID of the punk you would like to take off the
                market
              </span>
              <span className="text-sm">
                3. Click the Cancel button and verify the transaction in
                MetaMask
              </span>
            </div>
          }
        />
      )}
      <div className="flex self-start lg:px-14 flex-col pb-1 justify-between items-center bg-white pt-4 w-full border-b shadow-xl sticky left-0 z-40 right-0 top-0">
        <div className="flex self-start flex-col w-full lg:flex-row justify-between items-center">
          <div className="flex items-center">
            <span className="text-gray-900 mr-2 text-2xl font-bold">
              Marketplace
            </span>
            <span className="italic text-gray-900 ">BETA</span>
          </div>
          <div className="flex mt-4 lg:mt-0">
            <span
              onClick={() => setList(!list)}
              className={cs(
                "bg-gradient-to-r hover:from-harmony-blue hover:to-harmony-green pt-0 text-sm ml-2 cursor-pointer rounded-full border px-2 text-gray-900 font-semibold",
                { "bg-yellow-100": list }
              )}
            >
              List Punk
            </span>
            <span
              onClick={() => setShowRemoveModal(!showRemoveModal)}
              className={cs(
                "bg-gradient-to-r hover:from-harmony-blue hover:to-harmony-green pt-0 mr-2 text-sm ml-2 cursor-pointer rounded-full border px-2 text-gray-900 font-semibold",
                { "bg-gradient-to-r from-harmony-blue to-harmony-green": list }
              )}
            >
              Cancel Listing
            </span>
            <span
              onClick={() => setHelpModal(true)}
              className={cs(
                "bg-gradient-to-r hover:from-harmony-blue hover:to-harmony-green pt-0 mr-2 text-sm ml-0 cursor-pointer rounded-full border px-2 text-gray-900 font-semibold"
              )}
            >
              Help
            </span>
          </div>
        </div>

        <div className="flex text-sm mt-1.5 flex-wrap justify-center w-full">
          <div className="w-40 mb-1 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "body")}
              maxHeight={100}
              placeholder="Body"
              options={Body}
            />
          </div>

          <div className="w-40 mb-1 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "hair")}
              placeholder="Hair"
              options={Hair}
            />
          </div>

          <div className="w-40 mb-1 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "eyes")}
              placeholder="Eyes"
              options={Eyes}
            />
          </div>

          <div className="w-40 mb-1 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "beard")}
              placeholder="Beard"
              options={Beard}
            />
          </div>

          <div className="w-40 mb-1 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "mouth")}
              placeholder="Mouth"
              options={Mouth}
            />
          </div>

          <div className="w-40 mb-1 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "earring")}
              placeholder="Earring"
              options={Earring}
            />
          </div>
          <div className="w-40 mr-2 text-black">
            <Select
              isClearable={true}
              styles={customStyles}
              onChange={(a) => handleFilterChange(a, "necklace")}
              placeholder="Necklace"
              options={Necklace}
            />
          </div>
        </div>
      </div>
      {loading && (
        <span className="text-black w-full text-center mt-8 text-xl animate-pulse">
          Loading...
        </span>
      )}
      {list ? renderList() : renderBuy()}
    </div>
  );
}

export default Exchnage;
