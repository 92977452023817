import detectEthereumProvider from "@metamask/detect-provider";
import { useContext, useEffect, useState } from "react";

import {
  HarmonyOnePunksContext,
  HarmonyOnePunksMarketContext,
} from "../hardhat/SymfoniContext";

export function useONE() {
  const FTMCHAINID = "0x63564C40";
  const mintService = useContext(HarmonyOnePunksContext);
  const marketService = useContext(HarmonyOnePunksMarketContext);

  const [isNetwork, setIsNetwork] = useState(false);
  const [address, setAddress] = useState(null);
  const [isMetamask, setIsMetamask] = useState(false);

  const ONE_MAINNET_PARAMS = {
    chainId: "0x63564C40", // A 0x-prefixed hexadecimal chainId
    chainName: "Harmony Mainnet",
    nativeCurrency: {
      name: "Harmony One",
      symbol: "ONE",
      decimals: 18,
    },
    rpcUrls: ["https://api.harmony.one"],
    blockExplorerUrls: ["https://explorer.harmony.one"],
  };

  async function addNetwork() {
    if (isMetamask && isNetwork) {
      console.log("launching metamask");
      return launchMetamask();
    }
    const provider = await detectEthereumProvider();
    if (!provider) return;
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [ONE_MAINNET_PARAMS],
    });
  }

  async function launchMetamask() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAddress(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }
      }
    }
  }

  useEffect(() => {
    const fetcher = async () => {
      const provider = await detectEthereumProvider();
      console.log("connected to", mintService?.instance?.address);
      setIsNetwork(
        provider?.chainId?.toString().toLowerCase() ===
          FTMCHAINID.toString().toLowerCase()
      );
      setAddress(provider?.selectedAddress);
      setIsMetamask(provider?.isMetaMask);
    };

    fetcher();
  }, [mintService?.instance?.address]);

  return {
    isConnected: address && isNetwork,
    isNetwork,
    mintService,
    isMetamask,
    addNetwork,
    address,
    marketService,
  };
}
